.tableContainer {
  width: 100%;
  border-collapse: collapse;

  .colgroup {
    width: 100%;
  }

  .col {
    width: 20%;
  }

  .colLarge {
    width: 35%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        padding-bottom: 10px;
        padding-left: 20px;
        width: 10%;
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.hoverContainer {
  width: 100%;
}

.tableViewContainer {
  display: flex;
  border: 1px solid #d6dce8;
  background-color: #ffffff;
  border-top: none;
  min-height: 645px;
}

.inReviewEmptyState {
  margin-left: 200px;
}

.titleTableContainer {
  border-collapse: collapse;

  .col {
    width: 10%;
  }

  .colLarge {
    width: 20%;
  }

  .avatarColumn {
    margin-top: 6px;
    visibility: hidden;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 100px;
        padding-bottom: 10px;
        padding-left: 20px;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;
            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .container {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.titleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hoverContainer {
  position: relative !important;
}

.linksContainer {
  height: 100%;
  align-items: center;
  display: flex;
}

.progressContainer {
  position: relative;
}

.hoverStateContainer {
  visibility: hidden;
  width: 289px;
  color: white;
  position: absolute;
  right: 54%;
  background: #101828;
  transform: translateX(-25%);
  top: -26px;
  border-radius: 8px;
  z-index: 1000;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 20px 20px 20px;
  &.hidden {
    display: none;
  }
  max-height: 300px;
  overflow-y: auto;
}

.previewHoverState {
  visibility: hidden;

  &.hidden {
    display: none;
  }
}

.previewIconContainer {
  height: 20px;
  width: 20px;
}

.tail {
  visibility: hidden;
  height: 10px;
  width: 10px;
  background: #101828;
  position: absolute;
  left: -2px;
  top: -14px;
  transform: rotate(-45deg);
}

.hoverStateContainerBottom {
  visibility: hidden;
  width: 289px;
  color: white;
  position: absolute;
  right: 54%;
  background: #101828;
  transform: translateX(-25%);
  bottom: -28.75px;
  border-radius: 8px;
  z-index: 1000;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 20px 20px 20px;
  &.hidden {
    display: none;
  }
  max-height: 300px;
  overflow-y: auto;
}

.hoverStateContainerBottom::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.hoverStateContainerBottom::-webkit-scrollbar {
  width: 8px;
}

.hoverStateContainerBottom::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.hoverStateContainerBottom {
  scrollbar-color: #868e96 #2e313f;
  scrollbar-width: thin;
}

.hoverStateContainer::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.hoverStateContainer::-webkit-scrollbar {
  width: 8px;
}

.hoverStateContainer::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.hoverStateContainer {
  scrollbar-color: #868e96 #2e313f;
  scrollbar-width: thin;
}

.cardRightContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.approverCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.approverCard {
  display: flex;
  padding: 10px 0px 10px 0px;
  width: 100%;
  border-bottom: 1px solid #465366;
}

.approverCard:first-of-type {
  display: flex;
  padding: 5px 0px 10px 0px;
  width: 100%;
  border-bottom: 1px solid #465366;
}

.approverCard:last-of-type {
  display: flex;
  padding: 10px 0px 10px 0px;
  width: 100%;
  border-bottom: none;
}

.initialContainer {
  width: 32px;
  height: 32px;
  background: #475467;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.approverName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 0px;
  color: #ffffff;
}

.pending {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  height: 16px;
  background: #1d2939;
  border-radius: 16px;
}

.changeRequested {
  width: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  height: 16px;
  background: #1d2939;
  border-radius: 16px;
}

.approved {
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  height: 16px;
  background: #1d2939;
  border-radius: 16px;
}

.approveWithChanges {
  color: #3ccb7f;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  width: 156px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 16px;
  background: #1d2939;
  border-radius: 16px;
}

.submitNewVersion {
  width: 136px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  height: 16px;
  background: #1d2939;
  border-radius: 16px;
}

.waiting {
  color: #fac515;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.requestingChanges {
  color: #fac515;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.approved {
  color: #3ccb7f;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.rejected {
  color: #f97066;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.tableSubContainer {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #d6dce8 #f3f5f9;
  scrollbar-width: thin;
  width: 100%;
}

.emptyStateContainer {
  position: relative;
  height: 100px;
  margin-bottom: 75px;
}

.emptyMessageContainerAdmin {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.shareLinkGrey {
  height: 30px;
  margin-bottom: 20px;
}

.tableOneContainer {
  width: 7%;
  min-width: 100px;
}

.exportButton {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.exportButton:hover {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.externalLink {
  margin-left: 7px;
}

.tableSubContainer::-webkit-scrollbar-track {
  background-color: #f3f5f9;
}

.tableSubContainer::-webkit-scrollbar {
  height: 5px;
}

.tableSubContainer::-webkit-scrollbar-thumb {
  background-color: #d6dce8;
  border-radius: 8px;
}

.progressIndicator {
  min-width: 40px;
  text-align: center;
  background: #eaedf3;
  border-radius: 9.5px;
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #344054;
}

.releaseToApproved {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
  color: #c01048;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
}

.requestNewVersion {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fffaeb;
  border-radius: 16px;
  color: #b54708;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.submitApproval {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fef3f2;
  border-radius: 16px;
  color: #b42318;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.awaitingApproval {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #eaedf3;
  border-radius: 16px;
  color: #344054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.reviewSubmitted {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #f5fefd;
  border-radius: 16px;
  color: #1d9791;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.changesRequested {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fffade;
  border-radius: 16px;
  color: #df781c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.progress {
  display: flex;
  justify-content: space-between;
}

.progressBar {
  width: 175px;
  margin-right: 20px;
}

.ownerColumn {
  color: white;
  &Bold {
    color: #3e3f42;
  }
  margin-top: 10px;
  white-space: nowrap;
}

.ownerInitials {
  width: 32px;
  height: 32px;
  background: #9e165f;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  cursor: default;
}

.notOwnerInitials {
  width: 32px;
  height: 32px;
  background: #f2f4f7;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #475467;
  font-weight: 600;
  cursor: default;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-left: 22px;
}

.notificationAvatarContainer {
  display: flex;
  align-items: center;
}

.notificationIndicator {
  margin-right: 10px;
}

.favourite {
  margin-right: 10px;
  cursor: pointer;
}

.inReviewEmptyStateContainer {
  min-height: 400px;
  border: 1px solid #d6dce8;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.previewLink {
  cursor: pointer;
  max-width: 320px;
}

.newContainer[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.newContainer[data-title]:after {
  content: attr(data-title);
  background-color: #101828;
  color: #ffff;
  font-size: 10px;
  position: absolute;
  padding: 5px 5px 5px 5px;
  bottom: 1px;
  left: 13px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  height: 12px;
  line-height: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.favouriteContainer[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.favouriteContainer[data-title]:after {
  content: attr(data-title);
  background-color: #101828;
  color: #ffff;
  font-size: 10px;
  position: absolute;
  padding: 5px 5px 5px 5px;
  bottom: 1px;
  left: 25px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  height: 12px;
  line-height: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.openContainer {
  display: flex;
  margin-right: 10px;
}

.openContainer[data-title] {
  position: relative;
}

.openContainer[data-title]:after {
  content: attr(data-title);
  background-color: #101828;
  color: #ffff;
  font-size: 10px;
  position: absolute;
  padding: 5px 5px 5px 5px;
  bottom: 1px;
  top: 0px;
  left: -64px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  height: 12px;
  line-height: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.newContainer[data-title] {
  position: relative;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #101828;
  color: #ffff;
  font-size: 10px;
  position: absolute;
  padding: 5px 5px 5px 5px;
  bottom: 4px;
  left: 35px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
  height: 12px;
  line-height: 10px;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
[data-title] {
  position: relative;
}

.titleColumn {
  min-width: 75px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;

  .text {
    &Bold {
      color: #3e3f42;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      text-transform: uppercase;
    }
    color: #3e3f42;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    text-transform: uppercase;
  }

  .icon {
    margin-left: 5px;
    img {
      display: none;

      &.show {
        display: flex;
      }
    }
  }
}

.documentLink {
  color: #3e3f42 !important;
}

.captionContainer {
  display: flex;
}

.externalLinkIcon {
  // margin-left: 20px;
}

.loading {
  width: 21px;
  height: 21px;
  animation: spin 1s infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1900px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1850px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1800px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1750px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1700px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1650px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1600px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1550px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 54.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1525px) {
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 41.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 41.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1500px) {
  .progressBar {
    width: 130px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 41.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 41.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1475px) {
  .progressBar {
    width: 120px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1450px) {
  .progressBar {
    width: 120px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1425px) {
  .progressBar {
    width: 120px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1400px) {
  .progressBar {
    width: 120px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 40.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1350px) {
  .progressBar {
    width: 103px;
    margin-right: 20px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 34.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 34.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .previewLink {
    cursor: pointer;
    max-width: 185px;
  }
}

@media screen and (max-width: 1250px) {
  .previewLink {
    cursor: pointer;
    max-width: 150px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 27.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 27.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1200px) {
  .previewLink {
    cursor: pointer;
    max-width: 150px;
  }
  .hoverStateContainer {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 27.35%;
    top: -25px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
  .hoverStateContainerBottom {
    visibility: hidden;
    width: 289px;
    color: white;
    position: absolute;
    right: 27.35%;
    bottom: -27.75px;
    background: #101828;
    transform: translateX(-25%);
    border-radius: 8px;
    z-index: 1000;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    padding: 20px 20px 20px 20px;
    &.hidden {
      display: none;
    }
    max-height: 300px;
    overflow-y: auto;
  }
}
