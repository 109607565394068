.documentViewer {
  // min-width: 1280px;
  height: 100vh;
  // width: 100vw;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.rightContainer {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.documentContainer {
  flex: 0 0 auto !important;
  padding: 0 !important;
  width: calc(100vw - 320px) !important;
  max-width: 100% !important;
}

.header {
  border-top: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  padding: 20px 21px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftContainer {
    display: flex;
  }
}

.headerLegacy {
  margin-bottom: 40px;
}

.sidePanelContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto;
  height: 100vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 320px;
  background: #ffffff;
}

.sidePanel {
  border-left: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
  /*height: 100vh;*/
  /*min-height: 97vh;*/
  /*max-height: 100%;*/
  background-color: #fff;
}

.sidePanelSelectedText {
  background-color: #393d50;
  border-left: 1px solid #eaedf3;
  min-height: 100vh;
  height: 100%;
}

.sidePanelHeader {
  border-bottom: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  padding-top: 26px;
}

.sidePanelOptions {
  margin-bottom: 35px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 13px 30px;
  &:hover {
    .approvalsRemaining {
      transform: translateX(8px);
      transition-duration: 0.15s;
    }
  }
  &Approvals {
    border-bottom: none;
    padding-bottom: 0;
  }
  &MyApproval {
    padding: 0px 15px 0px;
    width: 90%;
  }
}

.documentActionsDropDown {
  float: right;
}

.documentActionsDropDownContainer {
  color: #393d50;
  cursor: pointer;
  font-size: 10px;
}

.annotaionLayersDropDownContainer {
  color: #393d50;
  cursor: pointer;
  font-size: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.editModeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 19px 0px 25px;
  height: 43px;

  background: #393e50;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #f79009;
  text-transform: uppercase;
}

.editIcon {
  margin-right: 10px;
}

.exportButton {
  // border: 1px solid black;
  border: none;
  padding: 10px 20px;
  background: none;
  margin-left: 20px;
  box-shadow: none;
}

.exportButtonGreen {
  // border: 1px solid #1b8c96;
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: #edf4f4;
  margin-left: 20px;
  box-shadow: none;
}

.exportButton:hover {
  // border: 1px solid black;
  border: none;
  background: #edeef1;
  box-shadow: none;
  padding: 10px 20px;
  margin-left: 20px;
}

.exportButtonGreen:hover {
  // border: 1px solid #1b8c96;
  border: none;
  background: #edf4f4;
  box-shadow: none;
  padding: 10px 20px;
  margin-left: 20px;
}

.layersIcon {
  margin-right: 10px;
}

.backLink {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  img {
    height: 18px;
  }
}

.renameContentContainer {
}

.renameContentHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.renameContentFormContainer {
  padding: 24px;
}

.renameContentFormButtons {
  padding: 20px 30px;
}

.renameCancelButton {
  margin-right: 20px;
}

.ownerTitle {
  font-size: 0.75rem;
  color: #9ea0a5;
  line-height: 1.125rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 8px;
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.approvalsRemaining {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
}

.approvalsRemainingIcon {
  i {
    float: right;
    color: #b6b6b6;
    font-size: 16px;
    margin-top: 2px;
  }
}

.approvals {
  display: block;
  float: left;
}

.uploadNewVersionLink {
  color: #3e3f42 !important;
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  font-size: 20px;
  float: right;
  height: 16px;
  width: 16px;
}

.notCommentPanel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.metadataField {
  margin-top: 10px;
}

.textMetadataField {
  margin: 10px 0 10px 0;
}

.metadataFieldLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.metadataFieldValue {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.metadataSingleLineInputFields {
  position: relative;
  float: left;
  width: 45%;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.metadataFormContainer {
  padding: 15px;
}

.editContentDetailsHeading {
}

.editDocumentFieldValuesForm {
  padding-bottom: 10px;
  clear: both;
}

.spacing {
  margin-bottom: 20px;
}

.tag {
  display: inline-block;
  background-color: #eaedf3;
  border-radius: 4px;
  padding: 4px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.tagsContainer {
  margin-top: 10px;
  max-width: 250px;
  border-left: 1px solid #eaedf3;
  padding-left: 28px;
  margin-top: -10px;
  padding-top: 20px;
}

.documentMessageContainer {
  width: 600px;
  margin: 212px auto 0;
  text-align: center;
}

.documentMessageContainerIcon {
  margin-bottom: 33px;
}

.documentMessageContainerMessage {
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 52px;
}

.documentMessageContainerButton {
  background: #1b8c96 !important;
  color: #fff;
  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.webViewer {
  height: 100%;
  width: 100%;
}

.invalidDocument {
  margin: 271px auto;
  max-width: 716px;
  img {
    display: block;
    margin: 0 auto;
    width: 48px;
  }
  .heading {
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }
  a {
    button {
      margin: 0 auto;
      display: block;
      width: 110px;
    }
  }
}

.processingErrorButtons {
  display: flex;
  justify-content: center;
}

.processingError {
  margin: 134px auto;
  max-width: 716px;
  img {
    display: block;
    margin: 0 auto;
    width: 48px;
  }
  .heading {
    margin-top: 30px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
  }
  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.processingErrorUploadButton {
  width: 188px;
}

.processingErrorPreviousVersionButton {
  width: 225px;
}

.viewerContentDeatils {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  float: left;
}

.libraryNotUpdatable {
  font-size: 11px;
  color: #393d50;
  font-weight: 500;
  line-height: normal;
  display: flex;
  margin-bottom: 20px;

  .libraryNotUpdatableText {
    margin-left: 10px;
  }
}

.downloadModalOption {
  clear: both;
  display: block;
  line-height: 30px;
  margin-top: 20px;
}

.downloadModalOption:first-of-type {
  margin-top: 0px;
}

.downloadModalOption .downloadButton {
  float: right;
}

.savingContentContainer {
  float: right;
  display: flex;
  margin-right: 20px;

  .text {
    color: #8a8e96;
    font-weight: 500;
    font-size: 10px;
    margin-right: 12px;
    margin-top: 3px;
  }

  .icon {
    margin-top: 7px;
    display: flex;
    position: relative;

    img {
      height: 18px;

      &.hidden {
        display: none;
      }
    }

    .formChangesSavedTooltip {
      display: none;

      .dimpleIcon {
        top: 16px;
        left: 8px;
        transform: rotate(90deg);
        position: absolute;
      }

      .tooltipText {
        position: absolute;
        width: 173px;
        top: 28px;
        height: 45px;
        left: 50%;
        font-weight: 500;
        font-size: 13px;
        color: white;
        padding: 10px 20px;
        box-sizing: border-box;
        transform: translateX(-50%);
        line-height: 24px;
        background: #2c3041;
        z-index: 1;
        border-radius: 8px;
      }
    }

    &:hover {
      .formChangesSavedTooltip {
        display: block;
      }
    }
  }
}

.downloadFormDocModalBody {
  .option {
    display: flex;
    justify-content: space-between;

    .text {
      color: #393d50;
      font-weight: 500;
      font-size: 14px;
    }

    &:nth-child(2) {
      margin-top: 20px;
    }
  }
}

.metadataFieldContainerLibrary {
  border-left: 1px solid #eaedf3;
  padding-left: 28px;
  margin-top: -10px;
}

.metadataFieldContainerOwner {
  border-left: 1px solid #eaedf3;
  padding-left: 28px;
  margin-top: -10px;
  padding-top: 10px;
}

.metadataFieldContainerMetadata {
  border-left: 1px solid #eaedf3;
  padding-left: 28px;
  margin-top: -10px;
  padding-top: 10px;
}

.divider {
  border-bottom: 1px solid #eaedf3;
  width: 90%;
  margin-bottom: 35px;
}

.approvalCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.approvedAndOwnerOrAdmin {
  display: flex;
  background: #eaedf3;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin: 20px;
  padding: 15px;
}

.toolTipContainer {
  position: relative;
}

.refIdTooltipBox {
  position: absolute;
  left: 10px;
}

.approvedAndOwnerOrAdminText {
  width: 90%;
}
@media screen and (min-width: 1440px) {
  .sidePanelContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow-y: auto;
    height: 100vh;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    width: 400px;
    background: #ffffff;
  }
  .documentContainer {
    flex: 0 0 auto !important;
    padding: 0 !important;
    width: calc(100vw - 400px) !important;
    max-width: 100% !important;
  }
  .tagsContainer {
    margin-top: 10px;
    max-width: 287px;
    border-left: 1px solid #eaedf3;
    padding-left: 28px;
    margin-top: -10px;
    padding-top: 20px;
  }
}
