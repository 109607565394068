.container {
  min-height: 100vh;
  width: 100%;
  .midContainer {
    width: 100%;
    display: flex;
    .row {
      min-height: 100vh;
      width: 100%;
      display: flex;
    }
  }
}

.body {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.midContainer {
  display: flex;
}

.gearIcon {
  height: 16px;
  width: 16px;
  margin-right: 20px;
  cursor: pointer;
}
