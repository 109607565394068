.sidePanelOptions {
  height: 32px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 12px 10px 12px;
  border-radius: 3px;
  &:hover {
    background: #e6f2f3;
    .approvalsLink {
      color: rgba(5, 125, 136, 1);
    }
    .approvalsRemaining {
      background: #dadee6;
      color: #3e3f42;
    }
  }
  &Approvals {
    border-bottom: none;
    padding-bottom: 0;
  }
  &MyApproval {
    padding: 13px 15px 20px;
  }
}

.mobileSidePanelOptions {
  height: 32px;
  width: 86%;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 12px 10px 12px;
  border-radius: 3px;
  margin-top: 15px;
  &:hover {
    background: #e6f2f3;
    .approvalsLink {
      color: rgba(5, 125, 136, 1);
    }
    .approvalsRemaining {
      background: #dadee6;
      color: #3e3f42;
    }
  }
  &Approvals {
    border-bottom: none;
    padding-bottom: 0;
  }
  &MyApproval {
    padding: 13px 15px 20px;
  }
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.approvals {
  display: flex;
  float: left;
  font-size: 14px;
}

.approvalsRemaining {
  float: right;
  width: 40px;
  text-align: center;
  background: rgba(234, 237, 243, 1);
  border-radius: 9.5px;
  line-height: 18px;
}

.approvalsRemainingIcon {
  i {
    float: right;
    color: #b6b6b6;
    font-size: 16px;
    margin-top: 2px;
  }
}

.icon {
  margin: 2px 15px 0px 0px;
}

.sectionContainer {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1440px) {
  .sidePanelOptions {
    height: 32px;
    width: 332px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 20px 12px 10px 12px;
    border-radius: 3px;
    &:hover {
      background: #e6f2f3;
      .approvalsLink {
        color: rgba(5, 125, 136, 1);
      }
      .approvalsRemaining {
        background: #dadee6;
        color: #3e3f42;
      }
    }
    &Approvals {
      border-bottom: none;
      padding-bottom: 0;
    }
    &MyApproval {
      padding: 13px 15px 20px;
    }
  }
}

.editTemplate {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
  font-size: 14px;
}
